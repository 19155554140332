<template>
    <div class="body" ref="pdf" id="pdf">
        <iframe frameborder="0" target="_top"  :src="src">   
        </iframe>
    </div>
</template>

<script>
    // import { toast  } from '@/utils/common.js';
    // import { setWaterMark, removeWatermark } from "../utils/watermark";
    export default {
        data() {
            return{
                src: '',
            }
            
        },
        activated() {
            var url = this.$route.query.url;
            if(url){
                this.initDoc(decodeURI(url));
                // this.initDoc(url);
            }
            
            this.hideTab();
        },
        methods:{
            initDoc(url){
                
                this.src = `https://view.xdocin.com/view?func=refresh&src=${url}`;
                 //this.src = `https://view.officeapps.live.com/op/view.aspx?src=${url}`;       
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            }
        }
    }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    .body{
        width: 100%;
    }
    iframe{
        width: 100%;
        min-height: 100vh;
    }
    iframe *{
        max-width: 100vw;
    }
</style>